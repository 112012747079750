import { motion, useScroll } from "framer-motion";
import logo from "../logo.svg";
import "./Styles/Navbar.scss";

function Navbar({ ref1, ref2, ref3 }) {
  const { scrollYProgress } = useScroll();

  function executeScroll(ref) {
    // if (ref.current) {
    //   ref.current.scrollIntoView({ behavior: "smooth" });
    // }
  }

  return (
    <div className="navbar sticky">
      <div className="navbar-container">
        <div className="navitem-container">
          <img
            onClick={() => executeScroll(ref1)}
            src={logo}
            className="App-logo"
            alt="logo"
          />
        </div>
        <button onClick={() => executeScroll(ref3)}>HIRE WITH US</button>
      </div>
    </div>
  );
}

export default Navbar;
