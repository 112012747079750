import "./Styles/HeaderTextSection.scss";

import { motion } from "framer-motion";

function HeaderTextSection() {
  return (
    <div className="header-text-container">
      <motion.div
        className="header-text"
        initial={{
          hidden: true,
          x: -200,
          y: 500,
          rotate: 10,
          opacity: 0,
        }}
        viewport={{ once: true }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 80,
          duration: 2.5,
          delay: 0.2,
        }}
      >
        <h1>
          WE MAKE HIRING <span className="header-text-orange">EASY, </span>
          <span className="header-text-orange">FAST, </span> <br></br> AND
          <span className="header-text-orange"> AFFORDABLE</span>
        </h1>
      </motion.div>
      <motion.div
        className="header-text"
        initial={{
          hidden: true,
          x: -200,
          y: 400,
          rotate: 10,
          opacity: 0,
        }}
        viewport={{ once: true }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 80,
          duration: 2.5,
          delay: 0.2,
        }}
      >
        <h2>
          Take advantage of near shoring and BDT to close the gap between you
          and top tier software talent.
        </h2>
      </motion.div>
      <motion.div
        className="header-button"
        initial={{
          hidden: true,
          x: -200,
          y: 250,
          rotate: 10,
          opacity: 0,
        }}
        viewport={{ once: true }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 80,
          duration: 2.5,
          delay: 0.2,
        }}
      >
        <button className="header-button-btn">COMPARE COSTS</button>
      </motion.div>
    </div>
  );
}

export default HeaderTextSection;
