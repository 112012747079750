import React from "react";

import { InlineWidget, PopupWidget } from "react-calendly";

import "./Styles/CalendlyEmbed.scss";

function CalendlyEmbed() {
  const currentUrl = window.location.href;
  console.log(currentUrl);
  return (
    <div id="calendly-embed">
      <InlineWidget
        style={{ width: "100%", height: "100%" }}
        url="https://calendly.com/adrian-visnieski-bdt/30min?hide_event_type_details=1&background_color=ffffff&text_color=023047&primary_color=fb8500"
      />
      <PopupWidget
        url="https://calendly.com/adrian-visnieski-bdt/30min?hide_gdpr_banner=1&background_color=ffffff&text_color=023047&primary_color=fb8500"
        rootElement={document.getElementById("root")}
        text="BOOK A CALL"
        textColor="#023047"
        color="#ffffff"
      />
    </div>
  );
}

export default CalendlyEmbed;
