import React, { useState } from "react";
import "./Styles/CharactersRoles.scss";

import { motion } from "framer-motion";

import character1 from "../Assets/character-1-noborder.svg";
import character2 from "../Assets/character-2-noborder.svg";
import character3 from "../Assets/character-3-noborder.svg";
import character4 from "../Assets/character-4-noborder.svg";

function CharactersRoles() {
  const [currentActiveElement, setCurrentActiveElement] = useState(0);
  return (
    <div className="header-image-container">
      <motion.div
        className=""
        initial={{
          hidden: true,
          x: 50,
          y: 0,
          rotate: 10,
          opacity: 0,
        }}
        viewport={{ once: true }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 80,
          duration: 1.5,
          delay: 0.4,
        }}
      ></motion.div>
      <div className="header-image">
        <motion.div
          className="header-text character-container"
          initial={{
            hidden: true,
            x: 50,
            y: 150,
            rotate: 10,
            opacity: 0,
          }}
          viewport={{ once: true }}
          whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 80,
            duration: 1.5,
            delay: 0.6,
          }}
        >
          <img
            className={currentActiveElement === 0 ? "active" : ""}
            onClick={() => setCurrentActiveElement(0)}
            src={character1}
            alt="project-manager-illustration"
          ></img>
        </motion.div>
        <motion.div
          className="header-text character-container"
          initial={{
            hidden: true,
            x: 50,
            y: 150,
            rotate: 10,
            opacity: 0,
          }}
          viewport={{ once: true }}
          whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 80,
            duration: 1.5,
            delay: 0.8,
          }}
        >
          <img
            className={currentActiveElement === 1 ? "active" : ""}
            onClick={() => setCurrentActiveElement(1)}
            src={character2}
            alt="backend-developer-illustration"
          ></img>
        </motion.div>
        <motion.div
          className="header-text character-container"
          initial={{
            hidden: true,
            x: 50,
            y: 150,
            rotate: 10,
            opacity: 0,
          }}
          viewport={{ once: true }}
          whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 80,
            duration: 1.5,
            delay: 1,
          }}
        >
          <img
            className={currentActiveElement === 2 ? "active" : ""}
            onClick={() => setCurrentActiveElement(2)}
            src={character3}
            alt="frontend-developer-illustration"
          ></img>
        </motion.div>
        <motion.div
          className="header-text character-container"
          initial={{
            hidden: true,
            x: 50,
            y: 150,
            rotate: 10,
            opacity: 0,
          }}
          viewport={{ once: true }}
          whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 80,
            duration: 1.5,
            delay: 1.2,
          }}
        >
          <img
            className={currentActiveElement === 3 ? "active" : ""}
            onClick={() => setCurrentActiveElement(3)}
            src={character4}
            alt="ui/ux-designer-illustration"
          ></img>
        </motion.div>
      </div>
      <motion.div
        className=""
        initial={{
          hidden: true,
          x: 150,
          y: 0,
          rotate: 10,
          opacity: 0,
        }}
        viewport={{ once: true }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 80,
          duration: 1.5,
          delay: 1.4,
        }}
      ></motion.div>
    </div>
  );
}

export default CharactersRoles;
