import "./Styles/Container.scss";

function Container({ width, children }) {
  return (
    <div className={"container"} style={{ width: width ? width : "90%" }}>
      {children}
    </div>
  );
}

export default Container;
