import { useState, useRef } from "react";

import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";

import Navbar from "./Components/Navbar";
import Section from "./Components/Section";
import Header from "./Components/Header";

import "./Components/Styles/App.scss";
import "./Components/Styles/Variables.module.scss";
import CostComparison from "./Components/CostComparison";
import CalendlyEmbed from "./Components/CalendlyEmbed";

function App() {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const { scrollYProgress } = useScroll();
  const scaleY = useSpring(scrollYProgress, {
    stiffness: 300,
    damping: 30,
    restDelta: 0.001,
  });

  return (
    <div className="app">
      <Navbar ref1={ref1} ref2={ref2} ref3={ref3} />
      <div className="content">
        <Section sectionRef={ref1} sectionId="header" color="">
          <Header></Header>
        </Section>
        <Section sectionRef={ref2} sectionId="info-1" color="">
          <CostComparison></CostComparison>
        </Section>
        <Section
          sectionRef={ref3}
          sectionId="info-2"
          color="rgba(245, 184, 61, 0.25)"
        >
          <CalendlyEmbed></CalendlyEmbed>
        </Section>
      </div>
      <div className="progress-container">
        <motion.div className="progress" style={{ scaleY }} />
      </div>
    </div>
  );
}

export default App;
