import "./Styles/CostComparison.scss";

import { motion } from "framer-motion";
import Container from "./Container";

import octopusBG from "../Assets/octopus-bg.png";
import rockBG from "../Assets/rock-section2-bg.png";
import CharactersRoles from "./CharactersRoles";

function CostComparison() {
  return (
    <div className="cost-comparison">
      <div className="background-color"></div>
      <motion.div
        className="background-container"
        initial={{
          hidden: true,
          x: 250,
          y: 250,
          rotate: -15,
          opacity: 0,
        }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 0.6 }}
        transition={{
          type: "smooth",
          stiffness: 80,
          duration: 1,
          delay: 0.2,
        }}
      >
        <img className="rock-bg" src={rockBG} alt="octopus illustration"></img>
      </motion.div>
      <motion.div
        className="background-container"
        initial={{
          hidden: true,
          x: -250,
          y: 250,
          rotate: -15,
          opacity: 0,
        }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 0.4 }}
        transition={{
          type: "smooth",
          stiffness: 80,
          duration: 1,
          delay: 0.2,
        }}
      >
        <img
          className="octopus-bg"
          src={octopusBG}
          alt="octopus illustration"
        ></img>
      </motion.div>
      <Container>
        <div className="content">
          <div className="section-text-container">
            <h3 className="main-text">Explore an untapped ocean of talent</h3>
            <h4 className="sub-text">
              Your first hire is completely{" "}
              <span className="bold-text">free</span>
            </h4>
            <CharactersRoles></CharactersRoles>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default CostComparison;
