import "./Styles/Section.scss";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";

function useParallax(value, distance) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

function Section({ sectionId, sectionRef, color, children }) {
  const { scrollYProgress } = useScroll({ target: sectionRef });
  const y = useParallax(scrollYProgress, 300);

  return (
    <div
      id={sectionId}
      ref={sectionRef}
      name={sectionId}
      className={`section section-${sectionId}`}
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  );
}

export default Section;
