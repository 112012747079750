import "./Styles/Header.scss";

import { motion } from "framer-motion";
import Container from "./Container";

import desktopBG from "../Assets/section1-desktop-mobile.png";
import mobileBG from "../Assets/section1-background-mobile.png";
import headerSunBG from "../Assets/sun.png";
import headerSunraysBG from "../Assets/rays.png";

import HeaderTextSection from "./HeaderTextSection";

function Header() {
  return (
    <div className="header">
      <div className="background-color"></div>
      <div className="desktop-background">
        <img
          className="desktop-background-shape"
          src={desktopBG}
          alt="background-shape"
        ></img>
      </div>
      <div className="mobile-background">
        <img
          className="mobile-background-shape"
          src={mobileBG}
          alt="background-shapes"
        ></img>
      </div>
      <motion.div
        className="background-container"
        initial={{
          hidden: true,
          x: 0,
          y: 100,
          rotate: 0,
          opacity: 0,
        }}
        whileInView={{ x: 0, y: 0, rotate: 0, opacity: 0.6 }}
        transition={{
          type: "smooth",
          stiffness: 80,
          duration: 1,
          delay: 0,
        }}
      >
        <img
          src={headerSunBG}
          className="sun"
          alt="background-sunrise-animation..."
        />
        <img
          src={headerSunraysBG}
          className="rotate sunrays"
          alt="background-sunrise-animation..."
        />
      </motion.div>
      <Container>
        <div className="header-content">
          <HeaderTextSection></HeaderTextSection>
          {/* <HeaderCharactersSection></HeaderCharactersSection> */}
        </div>
      </Container>
    </div>
  );
}

export default Header;
